<template>
  <div id="main-wrapper" class="inner-pages faq-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Frequently Asked Questions</h2>
      </div>
    </div>
    <div class="section faq-sec">
      <div class="wrapper">
        <div id="faq-container" class="faq-container">
          <div class="highlight-note t-left">
            <div v-for="(value, key) in faqs" :key="key">
              <div class="title-holder">
                <h3 class="t-center">{{ key }}</h3>
              </div>
              <div id="section1" class="accordion-holder">
                <div v-for="(faq, index) in faqs[key]" :key="faq.label">
                  <h3
                    @click="() => toggleClass(key, index)"
                    :class="{
                      active:
                        currentItem.key == key && currentItem.index == index,
                    }"
                  >
                    {{ faq.label }}
                  </h3>
                  <transition name="slide">
                    <div
                      v-show="
                        currentItem.key == key && currentItem.index == index
                      "
                      v-html="faq.content"
                    ></div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: {
        key: "Opening an Account",
        index: 0,
      },
      faqs: {
        "Getting Started": [
          {
            label: "How to register with AAAFx and verify my account?",
            content: `
              <div class="content">
              <p>For successful verification, the below process is to be followed:</p>
              <ul>
              <li>Step 1: Please provide your personal details including Name, Phone number, Email address etc.</li>
              <li>Step 2: After all the personal details have been furnished, kindly upload a Photo ID Proof (license, passport etc.)</li>
              <li>Step 3: For security purposes, please click a selfie on the spot and upload it on the required page.</li>
              <li>Step 4: At the next step, kindly upload a recent 'Proof of Residence' (issued within 3 months of the current date), containing your full name and address (a utility bill, bank statement, etc.)</li>
              <li>Step 5: Kindly fill in the details and click on Submit.</li>
              </ul>
              </div>
            `,
          },
          {
            label: "Which country do you accept clients from?",
            content: `<div class="content"><p>We accept clients from 27 countries under the European Economic Area (EU and Iceland, Switzerland and Liechtenstein). Clients from Belgium, and the UK are not accepted.</p></div>`,
          },
          {
            label: "Is AAAFx regulated?",
            content: `<div class="content"><p>AAAFx is a regulated financial service provider with licences from HCMC, Greece and FSCA, South Africa.</p></div>`,
          },
          {
            label:
              "Can I sign up without an email address/phone number, I don't have one?",
            content: `<div class="content"><p>Kindly note that you would need an email address/Phone number to sign up with us, as we send email confirmations on the registered email address to verify the request made from the web account. We would request you to provide your phone number as well, so that we can reach you in case of any urgency.</p></div>`,
          },
          {
            label: "What are the documents needed for account verification?",
            content: `<div class="content">
            <p>The following documents will be required to open an account with us:</p>
            <ul>
            <li>1. A valid photo ID Proof (such as passport, driving license, or any photo ID issued by a govt. office)</li>
            <li>2. A recent Residence proof (issued within 3 months), containing full name and address (it could    be a utility bill, bank statement, etc.). You can upload the above-mentioned necessary documents on the web account or email them at support@aaafx.com.</li>
            </ul>
            </div>`,
          },
          {
            label:
              "What if I need to change my details later? How do I update information on my profile?",
            content: `<div class="content">
            <p>You may change the following personal details by logging into your web account:</p>
            <ul>
            <li>Primary Phone</li>
            <li>Mobile Phone</li>
            <li>Gender</li>
            <li>Marital Status</li>
            </ul>
            <p>You may also change your bank details by logging into your web account. For updating other details, please reach out to us on Live Chat or email at support@aaafx.com.</p>
            </div>`,
          },
          {
            label: "How many Live accounts can I open?",
            content: `<div class="content"><p>You can open as many accounts as you wish. There is no restriction.</p></div>`,
          },
          {
            label: "In how many base currencies can I open an account?",
            content: `<div class="content"><p>You can open an account with a base currency like US Dollar, Euro, Canadian Dollar and Australian Dollar.</p></div>`,
          },
          {
            label: "How much is the minimum deposit to open an account?",
            content: `<div class="content"><p>The minimum deposit is only $100.</p></div>`,
          },
          {
            label: "Do you offer Islamic (swap free) accounts?",
            content: `<div class="content"><p>Yes, you can select Islamic account type while creating the request.</p></div>`,
          },
          {
            label: "Do you allow Hedging?",
            content: `<div class="content"><p>Yes, we do allow Hedging.</p></div>`,
          },
          {
            label: "Do you allow Scalping?",
            content: `<div class="content"><p>Yes, we do allow Scalping.</p></div>`,
          },
        ],
        "Deposits and Withdrawals": [
          {
            label: "How do I make a deposit in my account?",
            content: `<div class="content">
            <ul>
            <li>1. Log into your web account</li>
            <li>2. Go to Fund tab on left pane</li>
            <li>3. Select the Trading account that you wish to fund.</li>
            <li>4. Choose the preferred payment method you want to use.</li>
            </ul>
            </div>`,
          },
          {
            label: "How can I transfer funds between my accounts?",
            content: `<div class="content">
            <ul>
            <li>1. Log into your web account</li>
            <li>2. Go to Manage Accounts in left pane</li>
            <li>3. Click on 'Internal Transfer' button.</li>
            <li>4. Select the accounts and enter the amount you wish to transfer.</li>
            <li>5. Click Proceed. The funds will be transferred instantly.</li>
            </ul>
            <p>In case of internal transfer between accounts with different base currencies, the amount transferred will be as per the prevalent market prices.</p>
            </div>`,
          },
          {
            label: "How do I withdraw funds from my account?",
            content: `<div class="content">
            <ul>
            <li>1. Log into your web account.</li>
            <li>2. Go to Fund tab on left pane.</li>
            <li>3. Click on Withdrawals tab.</li>
            <li>4. Select the Trading ID from which you wish to make withdrawal.</li>
            <li>5. Enter the amount to be withdrawn and select the mode of withdrawal.</li>
            <li>6. Click on Create Transaction.</li>
            </ul>
            </div>`,
          },
          {
            label: "How long does it take to process a withdrawal?",
            content: `<div class="content"><p>All withdrawals are subject to 24 hours processing time, including weekends.</p></div>`,
          },
          {
            label: "Are there any deposit/withdrawal fees?",
            content: `<div class="content"><p>No, all deposits and withdrawals are free from our side.</p></div>`,
          },
          {
            label:
              "How long does it take to reflect the deposit in my trading account?",
            content: `<div class="content">
            <ul>
            <li><b>Bank Cards: </b>The payments are processed instantly.</li>
            <li><b>Bank Wire: </b>Bank wires transactions usually take 2-3 working days to reach us.</li>
            <li><b>eWallets: </b>The payments are processed instantly. It usually takes up to 1 business day to reflect the amount in your trading account if the deposit is initiated via Skrill.</li>
            <li><b>Cryptocurrencies: </b>Crypto deposits are credited within 30-40 minutes.</li>
            </ul>
            </div>`,
          },
          {
            label: "Do you reimburse the transaction fees?",
            content: `<div class="content"><p>AAAFx reimburses the transaction cost you incur while making a deposit with us maximum upto 5% of the deposit transaction value.</p></div>`,
          },
          {
            label:
              "What happens if I do not make the transactions in my account base currency?",
            content: `<div class="content"><p>When you make a deposit via any currency, funds will show up in your account base currency. The conversion will be done as per the best available market price at that point in time.</p></div>`,
          },
          {
            label: "When can I cancel a deposit request?",
            content: `<div class="content"><p>You can cancel any deposit request by mailing us at support@aaafx.com or you can come over to our live chat support and we will cancel the request for you.</p></div>`,
          },
          {
            label:
              "Is there any way to initiate a fund transfer to my trading account from another broker?",
            content: `<div class="content"><p>AAAFx does not support fund transfers between brokers. The laid down methods are to be followed to initiate funds transfer.</p></div>`,
          },
          {
            label:
              "Are there any limits on the minimum or maximum withdrawal amount?",
            content: `<div class="content"><p>The minimum withdrawal amount is $10. There is no ceiling limit on withdrawals.</p></div>`,
          },
          {
            label:
              "What is the procedure to withdraw via available funding methods?",
            content: `<div class="content">
            <ul>
            <li>Log into your web account</li>
            <li>Go to Fund tab on left pane</li>
            <li>Click on Withdrawals tab.</li>
            <li>Select the Trading ID from which you wish to make withdrawal.</li>
            <li>Enter the amount to be withdrawn and select the mode of withdrawal.</li>
            <li>Click on Create Transaction.</li>
            </ul>
            </div>`,
          },
          {
            label: "What is the standard withdrawal processing time frame?",
            content: `<div class="content"><p>Withdrawals are usually confirmed in 1 business day. From the moment they are released from your account until they are made available at your designated Bank account, it is a matter of a few business days subject to factors such as the destination country, the use of intermediary bank, etc.</p></div>`,
          },
          {
            label:
              "Can I initiate a withdrawal request through a method other than the one that I originally used to make the deposit?",
            content: `<div class="content"><p>If the deposit source is invalid due to some reason, you may reach us for the relevant action to be taken either over the live chat or email us at <a href="mailto:contactus@aaafx.com">contactus@aaafx.com.</a></p></div>`,
          },
          {
            label: "Do you process withdrawals over the weekend?",
            content: `<div class="content"><p>Yes, we process the withdrawals over the weekend.</p></div>`,
          },
          {
            label:
              "Are there any circumstances under which a withdrawal might get denied?",
            content: `<div class="content">
            <p>Yes, a withdrawal request might get denied. The possible reasons might include:</p>
            <ul>
            <li>Insufficient free margin</li>
            <li>Incorrect withdrawal details</li>
            </ul>
            <p>There might be other reasons which will be specified in case the withdrawal is denied.</p>
            </div>`,
          },
          {
            label: "When can I cancel a withdrawal request?",
            content: `<div class="content"><p>You can cancel any withdrawal request on your own, by e-mailing us at <a href="mailto:support@aaafx.com">support@aaafx.com</a>  or you can reach out to us at our Live Chat Support and we will cancel the request for you</p></div>`,
          },
          {
            label: "Are there any withdrawal conditions?",
            content: `<div class="content"><p>The withdrawal request shall be raised in the same funding method and of the same amount the deposit was made.</p><p>For eg; if a deposit of the amount of $50 was made using a bank card and $100 using a bank wire. Then, in this case, you can withdraw $50 using the bank card and $100 using the bank wire. You cannot initiate a withdrawal of more than $50 using the bank card or more than $100 using the bank wire.</p><p>In addition, when the withdrawn amount exceeds the amount deposited by a channel other than wire transfer (for example Instant Deposit via credit card or Moneybookers) the remaining withdrawal amount will be sent out by wire transfer.</p></div>`,
          },
          {
            label: "Can I withdraw my money during open trades?",
            content: `<div class="content"><p>You can only withdraw amounts up to the free margin available in your trading accounts. It is usually recommended to request withdrawal while you do not have any open trades.</p><p>However, in case you do have any open trades, you have to examine the most quantity which you would really like to withdraw. In case you do not have sufficient available margin in the trading account, the request can be denied.</p></div>`,
          },
          {
            label: "Do you accept any third-party payments?",
            content: `<div class="content"><p>No, we do not accept any third-party payments.</p></div>`,
          },
          {
            label:
              "How can I add and reduce funds to and from my demo account?",
            content: `<div class="content">
            <ul>
            <li>1. Log in to your web account.</li>
            <li>2. Go to Fund tab in left pane.</li>
            <li>3. Select Deposits for adding funds into Demo account.</li>
            <li>4. Select Withdrawals for reducing funds from Demo account.</li>
            <li>5. Select the account number and then enter the amount you wish to deposit/withdraw.</li>
            </ul>
            </div>`,
          },
        ],
        // "Loyalty Program": [
        //   {
        //     label: "How do I sign up for the loyalty program ?",
        //     content: `<div class="content"><p>There is no need to sign up. You just have to open and fund your AAAFx trading account and trade.</p></div>`,
        //   },
        //   {
        //     label: "What if my Loyalty route changes over time?",
        //     content: `<div class="content"><p>If you overlap in these two routes, you will be allotted the higher applicable tier out of the two routes. However, if your upgrade is via Steady route, then even if you later fall on to Express route, you will not get a downgrade further from the tier achieved via steady route.</p></div>`,
        //   },
        //   {
        //     label: "How can I redeem my loyalty points?",
        //     content: `<div class="content"><p>You can log in to your web account and select Loyalty Program. You will see all details of reward points earned.</p></div>`,
        //   },
        // ],
        "Forex Trading": [
          
          {
            label: "How to calculate profit/loss?",
            content: `<div class="content">
            <p>When the value of a currency that you have bought rises, then profit is created; you can secure the profit of this transaction by selling the currency back and thus closing the position. Let's see that with an example where we buy U.S. dollars and sell Swiss francs.</p>
            <p>The rate you are quoted is 1.2755 / 1.2760. Because you are buying U.S. dollars you will be working on the "ask" price of 1.2760, or the rate at which traders are prepared to sell.</p>
            <p>Let's assume you buy 1 standard lot (100,000 units) at 1.2760.</p>
            <p>A few hours later, the price moves to 1.2780 and you decide to close your trade.</p>
            <p>The new quote for USD/CHF is 1.2780 / 1.2785. Since you're closing your trade and you initially bought to enter the trade, you now sell in order to close the trade so you must take the "bid" price of 1.2780, or the rate at which traders are prepared to buy.</p>
            <p>The difference between 1.2760 and 1.2780 is .0020 or 20 pips.</p>
            <p>Using the pip calculation formula, we now have (.0001/1.2780) x 100,000 = $7.82 per pip x 20 pips = $156.49 Keep in mind that when you enter or exit a trade you are subject to the spread of the bid/offer quote.</p>
            <p>When you buy a currency, you will use the offer or ask price and when you sell, you will use the bid price.</p>
            </div>`,
          },
          {
            label: "What are AAAFx's trading hours?",
            content: `<div class="content"><p>Market opens on Sunday 22:00 UTC and closes on Friday 22:00 UTC.
When Eastern time is in daylight saving, market opens on Sunday 21:00 UTC and closes on Friday 21:00 UTC.</p></div>`,
          },
          {
            label:
              "Why do I see prices updating before the market opens but I cannot trade?",
            content: `<div class="content"><p>Even though Market opens on Sunday 00.00 you can notice slight fluctuation of rates prior to opening. The Trading Desk initiates relevant services and starts updating the rates in order to have them prepared and available when the trading resumes. However, the rates displayed prior to the official market open cannot be applied to new market orders and trading cannot be performed.</p></div>`,
          },
          {
            label:
              "How will my open position be affected, if the prices change from Friday's close to Sunday's open?",
            content: `<div class="content"><p>Under normal market conditions when the market opens you can see that the opening price of a currency pair is very close to the price registered on Friday before the market closed. However, there are times when the rates can move significantly up or down, and this can have a positive or a negative effect in your trading activity. If there is a major news announcement or important economic events that will have an impact on the value of the currency, there is a greater likelihood of gaps in the market.</p><p>Please bear in mind that as the price can move against your current position, you can find that your position is losing even though it was in profit when the market closed. For this reason, traders holding positions or orders over the weekend should be aware of such adverse eventual gaps and be ready for potential losses.</p></div>`,
          },
          {
            label:
              "Why did I get a Margin Call notification when the market was closed?",
            content: `<div class="content"><p>The market opening process is not instantaneous - it is a gradual process that starts before 22:00 UTC.</p><p>As a result, we also start receiving rates before the 22:00 point, so we generate a Margin Call or Stop Out notifications before that - however trades are not closed before official market opening at 22:00.</p><p>If after the market opening, the available rates still put your account below the Stop Out level, then the Stop Out mechanism is triggered, and closure of the trades is initiated.</p></div>`,
          },
          {
            label:
              "The price upon market open exceeded my predetermined Stop/Limit of a trade. What will happen to the trade?",
            content: `<div class="content"><p>If the predetermined Stop value is exceeded when the market opens, the trade will be closed by the prevailing rate at that time. The rate will depend on the liquidity available at that moment. The execution of Stop order at any exceeded rate implies bigger losses than expected.</p><p>However, when the market opens at a rate that exceeds your set a Limit value, the trade will be closed with a higher profit than expected.</p><p>Let's see this with an example:</p><p>You have a BUY EUR/USD position opened at price 1.1150 with Limit set to 1.1350.</p><p>On Friday when the market closed the price of the pair reached 1.1250, and on Sunday when the market opened with the price at 1.1450.</p><p>As you can see the price exceeded the Limit of 1.1350 so the trade would be closed by this new current price bringing you profit of 300 pips instead of the set 200 pips.</p></div>`,
          },
          {
            label: "How can I protect my trades held over the weekend?",
            content: `<div class="content"><p>If you believe that over the weekend the market may be volatile and the rates will move against your open positions, you should anticipate risk. If this trading strategy does not suit you, it is recommended that you get out of a trade by realizing your current profit or loss before the market closes.</p></div>`,
          },
          {
            label: "What is rollover/swap?",
            content: `<div class="content"><p>AAAFx  automatically rolls forward all open positions at 21:00 UTC. Trading is typically suspended for up to 1 minute during the roll process. The amount paid or earned depends on the direction of the open position and the interest rate differential between the two currencies involved. For example, assuming UK interest rates are significantly higher than Japan's, a trader long GBP/JPY (i.e. holding Pounds Sterling), is paid interest upon rollover. Conversely, if a trader is short GBP/JPY (i.e. holding Yen) interest will be debited upon the rollover.</p><p>AAAFx’s  daily rollover rates and detailed reporting of rollover activity is available in the Swap column of your Account History at the MT4 platform. For indicative rollover fees, please check our website.</p></div>`,
          },
          {
            label:
              "What are your Margin Requirements for a Stop-Out (Margin Call)?",
            content: `<div class="content"><p>The level at which a Stop-Out will be triggered in your account is the 70% of the margin requirement, e.g. if the margin requirement is 1,000$ and the equity of the account reaches 700$ then all of the open positions will be closed automatically.</p><p>Please note that AAAFx will issue a Margin Call when your equity reaches the 100% of the margin requirement and notify you by e-mail in order for you to take imminent action to prevent a Stop-Out.</p></div>`,
          },
          {
            label: "How do I avoid a Stop Out (Margin Call)?",
            content: `<div class="content">
            <p><b>Sufficient Margin</b></p>
            <p>In general, in order to avoid a Stop Out you should make sure you always have sufficient margin to maintain your current positions open and withstand their potential drawdown. As long as your equity is more than 70% of the margin used for your currently open positions, a Stop-Out will not occur in your account. Please note that you may monitor your Margin Level from your MT4 at any time; the higher the percentage of your Margin Level, the less likely you are to have a Stop Out.</p>
            <p>Please note that AAAFx  will issue a Margin Call when your equity reaches 100% of the margin requirement and notify you by e-mail in order for you to take imminent action to prevent a Stop-Out. Moreover, at 100% Margin Level the information line at the Terminal of your MT4 account will be highlighted from grey to red.</p>
            <p><b>Closing Open Positions</b></p>
            <p>A quick way to increase your Margin Level and reduce your chances of a Stop-Out is to close out some positions, so that the margin that was used to open them will be released back into your Equity.</p>
            <p><b>Fund Increase</b></p>
            <p>Another way of avoiding over-exposure, increasing your Margin Level and thus reducing your chance of a Stop-Out is by depositing more funds. You may use the Instant Deposit method to make immediate capital installations to your account. To do this, simply visit the Funds tab under your Client Login section.</p>
            <p><b>Avoid Overleveraging</b></p>
            <p>Another factor that may put your account at the risk of a Stop-Out is overleveraging. Using more leverage can potentially magnify your gains, but it can also magnify losses which will quickly deplete your usable margin. The more leverage you use, the faster your losses may accumulate.</p>
            <p>When you use excessive leverage, the fact that your account has more trading potential may easily lead you to overexpose it and thus cause you losses. To clearly see how this may happen, consider the following example:</p>
            <p>Trader A has an account with $500 equity and uses a leverage of 200:1,while Trader B has an account with $500 and 100:1 leverage.</p>
            <p>Trader A can trade up to 100,000 currency units and decides to trade 60% of his capital, so he opens 6 trades of 0.1 standard lots each (total of $60,000), and thus uses $300 of the margin ($50 x 0.6 lots) retaining $200 more as free margin. Every pip moving in this trade size is equal to $1, so if all open trades reach a low of 290 pips (loss of approximately 48 pips each trade), his Equity will be $210. When that happens, his margin level will be 70% (210/300), and hence a Stop Out will occur and close all open trades in his account.</p>
            <p>Trader B on the other hand can trade up to 50,000 currency units and decides to trade 60% of his capital. As in this case a 0.1 standard lot requires $100 margin, half of the capital is adequate for 3 trades of 0.1 standard lot each (total of $30,000). Again, every pip moving in this trade size is equal to $1, so if all open trades reach a low of 48 pips, the total low will be 144, hence his Equity will be 356. When that happens, his margin level will be 118% (356/300) so he will not be in danger of a Stop Out.</p>
            <p>In conclusion leverage is a tool that can enlarge your profits or losses by the same magnitude; the greater the amount of leverage on capital you apply, the higher the risk that you assume.</p>
            </div>`,
          },
          {
            label:
              "Why was my trade affected although I see on the MT4 chart that the critical rate was not reached?",
            content: `<div class="content">
            <p>Please note that the MT4 graphs show only Bid prices and not the Ask prices (the difference between them is the spread).</p>
            <p>Please also bear in mind that when you open a Sell trade, it opens at the Bid (Buy) price and closes at the Ask (Sell) price, while when you open a Buy trade it opens at the Ask (Sell) price and closes at the Bid (Buy) price.</p>
            <p>However, you can always see the exact rates your account is trading at from the Market Watch window of your MT4.</p>
            </div>`,
          },
          {
            label: "Does slippage occur with AAAFx  clients' orders?",
            content: `<div class="content">
            <p>Slippage refers to the difference between the requested price of an order and the price at which the order is actually executed. There are market events/conditions that cause high volatility and thus make slippage more likely to occur, such as market news or announcements.</p>
            <p>In such cases, AAAFx  fills the orders at the best available price, based on the requested price and depending on the amount as well as the type of the orders. This can cause an order to produce results that are more favorable, equal to or less favorable than original expectations with the results being referred to as positive slippage, no slippage and negative slippage, respectively.</p>
            </div>`,
          },
          {
            label: "Does AAAFx provide Negative balance protection?",
            content: `<div class="content"><p>AAAFx  operates on a 'negative balance protection' basis which means that the client cannot lose more than his/her overall invested capital (deposit). AAAFx  determines at its sole discretion and in good faith, that the occurrence of such negative balance is not due to any malicious or abusive behavior of the Customer and has not been intentionally caused by the Customer.</p></div>`,
          },
        ],
        "Metals and CFDs": [
          {
            label: "What is the minimum lot size for trading Metals and CFDs?",
            content: `<div class="content"><p>The minimum lot size for Metals and CFDs is 1 standard lot.</p></div>`,
          },
          {
            label:
              "What is one standard lot of gold (XAU/USD) and silver (XAG/USD) equal to?",
            content: `<div class="content"><p>1 standard lot of gold (XAU/USD) is equal to 1 ounce (oz) of gold and 1 standard lot of silver (XAG/USD) is equal to 50 ounces (oz) of silver.</p></div>`,
          },
          {
            label:
              "What is the margin required to trade in CFDs and what are their trading hours?",
            content: `<div class="content"><p>Please find all information about CFDs in the Glossary, CFDs section.</p></div>`,
          },
          {
            label:
              "What is the margin required to trade in gold (XAU/USD) and silver (XAG/USD) at my AAAFx  account?",
            content: `<div class="content"><p>The margin required for gold (XAU/USD) is 100% of the current price and the pip value is $0.01. The margin required for silver (XAG/USD) is 200% of the current price multiplies with Contract size (multiplier) and the pip value is $0.5.</p></div>`,
          },
          {
            label:
              "Why was my USOilSpot/UKOil/NGAS/BUND/Copper trade closed? The stop or limit was not reached and I didn't receive a Margin Call either.",
            content: `<div class="content"><p>German bonds have expiration dates, as per the table in the Commodities and Stocks Section, Expiration dates. As a result, clients will realize any floating P/L at the time the position is closed due to expiration.</p></div>`,
          },
          {
            label:
              "What is the margin required to trade in BTC/USD at my AAAFx account?",
            content: `<div class="content"><p>The margin required for Bitcoin (BTC/USD) is 1000% of the current price and the pip value is $0.01.</p></div>`,
          },
          {
            label: "What is the commission charged for a BTC/USD contract?",
            content: `<div class="content"><p>The commission is  0.0008*Price in ECN Plus account.</p></div>`,
          },
          {
            label: "What is the minimum contract size for trading BTC/USD?",
            content: `<div class="content"><p>There is a minimum of 1 contract size = 1 BTC. This means that with approx. $35,000 BTC/USD rate, you need $7,000 margin to open (Buy or Sell) the minimum order.</p></div>`,
          },
          {
            label:
              "What is the margin required to trade in ETH/USD on my AAAFx account?",
            content: `<div class="content"><p>The margin required for Ethereum (ETH/USD) is 1000% of the current price.</p></div>`,
          },
          {
            label:
              "What is the maximum number of ETH/USD contracts per client?",
            content: `<div class="content"><p>There is a limit of 1000 contracts of total exposure (e.g. 500 buy and 500 sell = 1K) per client.</p></div>`,
          },
          {
            label:
              "What is the margin required to trade in LTC/USD at my AAAFx account?",
            content: `<div class="content"><p>The margin required for Litecoin (LTC/USD) is 1000% of the current price.</p></div>`,
          },
          {
            label:
              "What is the maximum number of LTC/USD contracts per client?",
            content: `<div class="content"><p>There is a limit of 1000 Contracts of total exposure (e.g. 500 buy and 500 sell = 1K) per client.</p></div>`,
          },
        ],
        "Basic Forex Information": [
          {
            label: "What is Forex?",
            content: `<div class="content"><p>The foreign exchange market (Forex, FX, or currency market) is a form of exchange for the global decentralized trading of  currencies. Financial centers around the world function as anchors of trading between a wide range of different types of buyers and sellers around the clock, with the exception of weekends. The foreign exchange market determines the relative values of different currencies</p></div>`,
          },
          {
            label: "How big is the Foreign Exchange Market?",
            content: `<div class="content"><p>The foreign exchange market is the most liquid financial market in the world. The average daily turnover in the global foreign exchange and related markets is continuously growing. According to the 2010 Triennial Central Bank Survey, coordinated by the Bank for  Settlements, average daily turnover was US$3.98 trillion in April 2010 (vs. $1.7 trillion in 1998). Of this $3.98 trillion, $1.5 trillion was spot transactions and $2.5 trillion was traded in outright forwards, swaps and other derivatives.</p></div>`,
          },
          {
            label: "Where is the Foreign Exchange Market based?",
            content: `<div class="content"><p>There is no unified or centrally cleared market for the majority of trades, and there is very little cross-border regulation. Due to the over-the-counter (OTC) nature of currency markets, there are rather a number of interconnected marketplaces, where different currencies instruments are traded. This implies that there is not a single exchange rate but rather a number of different rates (prices), depending on what bank or market maker is trading, and where it is located.</p></div>`,
          },
          {
            label: "Who participates in the market?",
            content: `<div class="content"><p>Unlike a stock market, the foreign exchange market is divided into levels of access. At the top is the interbank market, which is made up of the largest commercial banks and securities dealers. The top-tier interbank market accounts for 53% of all transactions. From there, transactions take place from smaller banks, followed by large multi-national corporations, large hedge funds, and even some of the retail market makers. However, the share of other market participants like brokers, multinational corporations, registered dealers, as well as private investors is rapidly increasing.</p></div>`,
          },
          {
            label: "What is a Lot?",
            content: `<div class="content"><p>A Lot is the standard unit size of a transaction. Typically, for Forex currencies, one standard lot is equal to 100,000 units of the base currency, 10,000 units if it's a mini lot, or 1,000 units if it's a micro lot.</p></div>`,
          },
          {
            label: "What is the Spread?",
            content: `<div class="content"><p>The difference between the Bid and Ask prices is referred to as the Spread. AAAFx offers amongst the lowest spreads in the Forex Market.</p></div>`,
          },
          {
            label: "What is a Pip/Tick?",
            content: `<div class="content"><p>A Pip (or Tick) is the smallest price increment a currency can make. It is the fourth digit in all currency pairs with the exception of the Japanese Yen (JPY) where it is the second digit (For example, 1 pip = 0.0001 for EUR/USD, or 0.01 for USD/JPY.)</p></div>`,
          },
          {
            label: "How do I calculate the value of 1 Pip?",
            content: `<div class="content">
            <p>To express the value in the terms currency, multiply 1 pip with the lot size.</p>
            <ul>
            <li>EUR/USD pip = 0.0001 X 100,000 = $10.00 (for standard lots)</li>
            <li>EUR/USD pip = 0.0001 X 10,000 = $1.00 (for mini lots)</li>
            <li>EUR/USD pip = 0.0001 X 1,000 = $0.1 (for micro lots)</li>
            <li>GBP/JPY pip = 0.01 X 100,000 = 1000 ¥ (for standard lots)</li>
            </ul>
            <p>To convert to the base currency divide by the exchange rate:</p>
            <p>Say EUR/USD exchange rate = 1.4750, then $10.00 / 1.4750 = 6.78€</p>
            <p>To convert to an account's currency (e.g. USD) divide by the exchange rate $/¥:</p>
            <p>If $/¥ = 82.12 then 1000 ¥ / 82.12 = 12.17$</p>
            </div>`,
          },
          {
            label: "What are the seven Major Currency Pairs?",
            content: `<div class="content">
            <p>The seven most liquid pairs are traded against the US dollar with the first four pairs being the majors, followed by three commodity pairs. Other currency pairs are better known as the currency crosses, the most popular crosses being those that include the Euro: EUR/JPY, EUR/GBP and EUR/CHF.</p>
            <p>The major currency pairs are:</p>
            <ul>
            <li>EUR/USD</li>
            <li>USD/JPY</li>
            <li>USD/CHF</li>
            <li>GBP/USD</li>
            </ul>
            <p>The commodity currency pairs are:</p>
            <ul>
            <li>AUD/USD</li>
            <li>NZD/USD</li>
            <li>USD/CAD</li>
            </ul>
            </div>`,
          },
          {
            label: "What is Leverage?",
            content: `<div class="content"><p>Forex Leverage or Leverage simply means the % amount of money you are allowed to borrow from the broker when you open a position. Leverage determines the percentage of a trade's nominal value that is required as margin. In simple terms, you can think Leverage as a loan that fronts you to buy currencies.</p></div>`,
          },
          {
            label: "What is Margin?",
            content: `<div class="content"><p>Typically, in order for the Leverage to be released, Brokers will request a certain amount of the account's actual funds to be allocated per each position (lot) traded. So, margin is the minimum amount which is escrowed in order to open a position. Margin depends on the account's leverage; for example, if the leverage is 1:30 then you are able to trade a position thirty times larger than the escrow amount. In simple terms, you can think of Margin as a good faith deposit required to release the loan (leverage).</p><p>For example, having a leverage of 100:1 (or 1% of the position required) allows you to trade a position worth $100,000 with an equity of just $5,000. This would be possible as the broker would set aside $1,000 as down payment, or "margin", and let you "borrow" the rest. Of course, any losses or gains will be deducted from or added to the remaining cash balance of your account.</p></div>`,
          },
        ],
        "Stock CFDs": [
          {
            label: "How do Stock CFDs Work?",
            content: `<div class="content"><p>Stocks (or shares) of listed companies can be speculated with. Online brokers may offer contracts for difference (CFDs) with different companies' stocks as the underlying assets for these CFDs. When a stock CFD is bought, the buyer does not actually own the stock itself but merely speculates on the price movement of that stock by means of the CFD.</p><p>With stock CFDs (contracts for difference), investors can go long (buy) or go short (sell). With a long position, a rise in price will produce a profit. On the other hand, a short position makes money when the price of a stock moves lower.</p></div>`,
          },
          {
            label: "What Moves the Stock Market?",
            content: `<div class="content"><p>The price of a stock is influenced by supply and demand. The forces that influence supply and demand are manifold - fundamental factors (e.g. earnings per share), investor sentiment, technical factors, news about the particular company or its industry sector, etc.</p></div>`,
          },
          {
            label:
              "What is the difference between Stocks and Stock CFDs trading?",
            content: `<div class="content"><p>CFD trading mimics share trading with the exception that in a contract for difference, you actually don't own the underlying asset, unlike company shares, where you do. What you are essentially doing with CFD trading is buying a contract between yourself and the CFD provider that, depending on your position as (either 'Short Sell' or 'Buy'), will have an entry price when entering a trade, and an exit price when clearing out your trade with an equal opposite position.</p><p>One of the key differences between trading a CFD long vs buying a security is that you can enjoy larger leverage features. Contracts for difference are traded on margin, meaning there is no need to tie up the full market value of purchasing the equivalent stock position.</p><p>There are also a number of key differences between trading an underlying asset and a CFD:</p>
            <ul>
            <li>CFDs stocks can be traded long or short, and you are not required to deliver the underlying asset in the event of a short sale.</li>
            <li>The investor doesn't own the underlying asset over which the CFD is based, but instead enters a contractual agreement with the CFD broker, to exchange the cash difference in the price between the opening and closing prices of the contract.</li>
            <li>As opposed to holding the underlying asset, a CFD is traded on margin which means that an initial deposit is lodged with the CFD broker, which allows the investor to buy or sell a number of CFDs according to margin computations, which generally allow extra leverage over the stock purchase itself.</li>
            </ul>
            </div>`,
          },
          {
            label: "What is the leverage for Stock CFDs?",
            content: `<div class="content"><p>Like all other Equities Stock CFDs Leverage Level is to 1:5. Example: Let's assume that you buy 5 Facebook CFDs at $400. The margin requirement is 20%, you would only be required to outlay $100, thus leaving you with more money to utilise on other trades.</p></div>`,
          },
          {
            label: "Which Stock CFDs do you offer?",
            content: `<div class="content"><p>At the moment, we offer the most popular stocks from DOW30 and Nasdaq please see a full list <a href="/stocks">here</a></p></div>`,
          },
          {
            label: "What are the trading hours for Stocks CFDs trading?",
            content: `<div class="content"><p>Unlike the Forex market which can basically be traded 24 hours a day, 5 days a week, the global stock markets are only open for about 6.5 to 8.5 hours a day, 5 days a week, depending on the exchange. The times at which different countries' stocks can be traded also differ according to the timezone of each country.</p><p>We support U.S. Stock Market CFDs, thus, we follow the regular Trading Session hours of the U.S. Stock Market (NASDAQ, Dow 30): Workdays 09:30 a.m to 4:00 p.m (Eastern Standard Time -- EST).</p><p>For Greek Stock CFDs the trading hours are: Workdays 08:35-15"00 (UTC)</p></div>`,
          },
          {
            label: "What are Corporate Actions?",
            content: `<div class="content"><p>Corporate actions are events initiated by a publicly traded company that usually have a direct or indirect impact on shareholders and result in material changes to it's stock by affecting price, number of traded shares or other characteristics (e.g. ticker name). Corporate actions include stock splits, dividends, mergers, acquisitions, rights issues and spin-offs. All of these are major decisions that typically need to be approved by the company's board of directors and authorized by its shareholders. Corporate actions are public information and the relevant action dates are available in various online sources.</p></div>`,
          },
          {
            label: "What is a stock dividend?",
            content: `<div class="content"><p>A dividend is a reward that companies pass to their shareholders and it's coming from the company's net profit. Such rewards can either be in the form of cash, cash equivalent, shares, etc. and are mostly paid from the remaining share of profit once essential expenses are met. In the simplest terms, a dividend is an investor's share of a company's profits.</p></div>`,
          },
          {
            label: "Who is entitled to get Dividends?",
            content: `<div class="content"><p>When you buy some stock CFDs in a company, you usually benefit from any dividends that are paid out by the company on those stocks. Stock CFD Investors are entitled to the dividends if they hold the stocks until the ex-dividend date. Depending on the type of the position (long/short), they will either receive or be charged dividends on the payment date.</p><p>If you buy a stock CFD on its ex-dividend date or after, you will not receive the upcoming dividend payment. Instead, the seller in this situation will get the dividend.</p><p>Please note that Stock dividends will be paid to your account with any applicable withholding taxes deducted. The payment will actually appear on your account as additional cash.</p></div>`,
          },
          {
            label: "What is a stock split?",
            content: `<div class="content"><p>When a company declares a stock split, the number of shares of that company increases, but the market cap remains the same.This action, which requires advance approval from the company's board of directors, usually involves the issuance of additional shares to existing stockholders.</p><p>For example ABC company has 4 million shares outstanding and each share has value $10. In this case, the company's total market value is $40 million (4 million x $10/share). After a two-for-one stock split, the firm's number of shares will double to 8 million, while the value of those shares will be cut in half to $5. However, the company's total market capitalization will remain the same at just $40 million (8 million* $5/share).</p></div>`,
          },
          {
            label: "Do I get dividends from Stock CFDs?",
            content: `<div class="content"><p>Stock CFD Investors depending on the type of the position (long/short) are entitled to be credited or charged the dividend payment if they hold a stock position after the end of ex-dividend date. Dividends are public information and the dates can be easily found online.</p></div>`,
          },
          {
            label: "What is the Minimum Trade Size?",
            content: `<div class="content"><p>The minimum trade size is 1 lot per order which represents one stock.</p></div>`,
          },
          {
            label: "Which is the official Holiday Schedule for US Stocks?",
            content: `<div class="content"><p>Please see below the days that US Market is officially closed:</p>
            <ul>
            <li>Good Friday</li>
            <li>Memorial Day</li>
            <li>Independence Day</li>
            <li>Labor Day</li>
            <li>Thanksgiving Day</li>
            <li>Christmas Friday</li>
            </ul>
            </div>`,
          },
        ],
        "Trading Platforms - General": [
          {
            label: "What prices does the chart on the trading platform show?",
            content: `<div class="content"><p>The charts on the trading platform show bid prices by default. However, you can view the ask line by following the steps below:</p>
            <ul>
            <li>Right-click anywhere on your chart and select Properties.</li>
            <li>Click on the Common tab and check the Show Ask line box.</li>
            <li>Click on the OK button and you will see the Ask line appear on the MT4 chart.</li>
            </ul>
            </div>`,
          },
          {
            label:
              "How can I generate my trading account statement on the trading platform?",
            content: `<div class="content"><p>Kindly follow the steps below to download the trading account statement from the trading platform:</p>
            <ul>
            <li>Login to your trading account</li>
            <li>Click on the "Account history" tab</li>
            <li>Right-click on the blank area and then click on " all history"</li>
            <li>Once the complete history pops up, right-click and click on "Save as detailed report"</li>
            </ul>
            <p>The statement will get downloaded on your device.</p>
            </div>`,
          },
          {
            label:
              "How do I hide or show more pairs/ instruments in the market watch window?",
            content: `<div class="content">
            <ul>
            <li>Right-click on any instrument</li>
            <li>Choose the “symbols” option</li>
            </ul>
            <p>From among the list of forex, indices and commodities choose whichever pair you want to hide or show.(Shortcut: Ctrl U)</p>
            </div>`,
          },
          {
            label: "How do I see details about a trading instrument?",
            content: `<div class="content"><p>To see the details about a trading instrument :</p>
            <ul>
            <li>Right-click on the trading instrument</li>
            <li>Choose “Specification”.</li>
            </ul>
            <p>You can check through the contract specification dialogue box for relevant information like spread, contract size, swap points, etc</p>
            </div>`,
          },
          {
            label: "How can I Activate one-click trading on the platform?",
            content: `<div class="content"><p>Shortcut: Alt T or Right-click on the chart and enable One-Click Trading</p></div>`,
          },
          {
            label: "What is an Investor Password? Why do I need it?",
            content: `<div class="content"><p>The investor password is another name for 'read-only password'. The investor password comes into play when you want a third party to view trades in your account without carrying on any other activity. To generate the investor password, log in to your trading account. Click on Tools>Options>Server and then select Change.</p><p>You may change/generate the investor password directly from your web account as well. Follow the below-given steps:</p><ol><li>Login to your web account</li><li>Select 'Manage Accounts' from the left pane</li><li>Click on 'Reset Trading Password' > 'Investor Password'</li></ol></div>`,
          },
          {
            label: "Is there any way to change the time on my charts?",
            content: `<div class="content"><p>The timings on the charts on trading platforms are according to the server time. At AAAFx, the server time is set to UTC OR UTC+1 depending on daylight savings.</p></div>`,
          },
          {
            label:
              "Can I customize my charts on the trading platform? If yes, how?",
            content: `<div class="content"><p>Yes, charts can be customized by following the steps below:</p>
            <ul>
            <li>Right-click on the chart window.</li>
            <li>Select the option 'Properties'</li>
            <li>You can change the chart colours as per your choice.</li>
            </ul>
            <p>For other modifications, select the 'Common' tab.</p>
            </div>`,
          },
          {
            label:
              "Can I log in to more than one trading account at the same time?",
            content: `<div class="content"><p>Yes, you can log in to multiple trading accounts on a single trading platform. However, trading can only be carried out on one trading account at one time.</p></div>`,
          },
        ],
        "Trading Platforms - MT4": [
          {
            label: "How to log in to my MT4 trading account?",
            content: `<div class="content"><p>To log in to the MT4 platform please follow these steps</p>
            <ul>
            <li>Click on the “File” option in the menu bar</li>
            <li>Select login to trade account</li>
            <li>Fill in your trading id and password</li>
            <li>Select the server name: AAAFx-FX-Demo (For Demo Account) / AAAFx-FX-Real (For Real Account)</li>
            <li>Click on login</li>
            </ul>
            <p>You'll be logged into your trading account</p>
            </div>`,
          },
          {
            label: "How do I log in to a real account on MT4?",
            content: `<div class="content"><p>To login to a real account on the MT4 platform, please follow these steps:</p>
            <p>First, you need to create a real account on the portal. To create a live account please follow these steps:</p>
            <ul>
            <li>Register: Click on the “Join now” option on the portal</li>
            <li>Enter your details: Name, email ID, phone number</li>
            <li>Enter the verification code you would have received in your mail</li>
            <li>Choose platform, account type, account's base currency , leverage and lastly set the password to the account.</li>
            <li>Further, you need to upload documents</li>
            <li>Now deposit funds</li>
            </ul>
            <p>After this, follow these steps to log in to the platform:</p>
            <ul>
            <li>Click on the “File” option in the menu bar</li>
            <li>Choose “Login to a trade account”</li>
            <li>Enter your trading ID and password</li>
            <li>Select Server: AAA Fx-FX-Real</li>
            <li>Click on 'Login'</li>
            </ul>
            </div>`,
          },
          {
            label: "How do I create/log in to a demo account?",
            content: `<div class="content"><p>To create a demo account, please follow these steps:</p>
            <ol>
            <li>Log in to your web account.</li>
            <li>Go to "Manage Accounts"</li>
            <li>Click on Create a Demo account"</li>
            <li>Choose the trading platform, account leverage, account's base currency and set password.</li>
            </ol>
            <p>Now a demo account has been created for you.</p>
            </div>`,
          },
          {
            label: "Where can I view my commissions?",
            content: `<div class="content"><p>To see commission, please follow these steps:</p>
            <ul>
            <li>Click on the “View” option in the menu bar</li>
            <li>Choose “Terminal”</li>
            <li>Select “Trade Section”</li>
            <li>Right-click on the trade placed</li>
            <li>Enable commissions </li>
            </ul>
            <p>You can now see commissions as a separate column</p>            
            </div>`,
          },
          {
            label:
              "How can I change my trading account password from the platform?",
            content: `<div class="content"><p>Follow these steps to change the account password from MT4:</p>
            <ul>
            <li>Click on “Tools” in the menu bar</li>
            <li>Select “Options” > Dialogue Box will appear</li>
            <li>Enter new password > Okay</li>
            </ul>
            <p>OR</p>
            <ul>
            <li>In the navigation window, under accounts > Right-click on the account that you want to change the password for</li>
            <li>Enter the current password and new password > Click on Okay</li>
            </ul>
            </div>`,
          },
          {
            label: "How can I delete/ remove my account from the MT4 platform?",
            content: `<div class="content"><p>To remove or delete your account from the MT4 platform, Right-click on the account that you need to remove and just select the “Delete” option.</p></div>`,
          },
          {
            label: "From where can I download the MT4 platform?",
            content: `<div class="content"><p>To download the platform please follow these steps:</p>
            <ul>
            <li>Click on the “Platform” tab on the portal</li>
            <li>Click on “Download here”</li>
            </ul>
            </div>`,
          },
          {
            label: "Am I allowed to use an EA/ BOT/ Trading Robot?",
            content: `<div class="content"><p>You can definitely use an Expert Advisor in MT4. Check for the ones that are freely available in the navigation window. If you want you can also purchase EA's from the MQL 5 website.</p></div>`,
          },
          {
            label: "How can I attach EA in MT4?",
            content: `<div class="content"><p>To attach an EA, please follow these steps:</p>
            <ul>
            <li>Open Navigator Box (Ctrl N)</li>
            <li>Expert Advisor</li>
            <li>Right-click on EA that you want to use</li>
            <li>Attach to chart </li>
            </ul>
            <p>And in case you are willing to apply for a purchased EA from the MQL site, please follow these steps:</p>
            <ul>
            <li>Go to the “File” tab in the menu bar</li>
            <li>Click on Open Data Folder </li>
            <li>Open the “Experts” folder in the “MQL” file.</li>
            <li>Open the “Experts” folder in the “MQL” file.</li>
            <li>Reopen the MT4 platform again.</li>
            </ul>
            </div>`,
          },
          {
            label: "How do I set up default charts with indicators on MT4?",
            content: `<div class="content"><p>Please follow the steps below:</p>
            <p><b>To Save A Profile:</b></p>
            <p>Once you have everything positioned the way that you want, please go to File - Profiles and click “Save As” Enter a profile name and then press “Ok”</p>
            <p>To load a saved profile, please go to File - Profiles and then select the profile from the list of profiles that comes up. The profile should load.</p>
            <p><b>To Save A Template:</b></p>
            <p>Once you have everything set up on your chart the way you want, please go to Charts - Template - Save Template to save the Template. Enter a name for the template and press save.
            </p>
            </div>`,
          },
          {
            label: "Can I use multiple trading accounts in MT4?",
            content: `<div class="content"><p>Yes, you can use multiple trading accounts in MT4, to add an account follow these steps:</p>
            <ul>
            <li>Right-click on accounts in the navigator box</li>
            <li>Choose “Login to trading account”</li>
            <li>Fill in your trading ID and password</li>
            </ul>
            <p>Your trading account will be inserted in MT4.</p>
            </div>`,
          },
          {
            label: "Where can I check my trading account connection status?",
            content: `<div class="content"><p>Connection status can be checked from the bottom right corner below the terminal window.</p><p>To make the connection status visible, click on “view” > enable status bar</p></div>`,
          },
        ],
        "Trading Platforms - MT5": [
          {
            label: "How to log in to my MT5  trading account?",
            content: `<div class="content"><p>To log in to the MT5 platform please follow these steps</p>
            <ul>
            <li>Click on the “File” option in the menu bar</li>
            <li>Select login to trade account</li>
            <li>Fill in your trading id and password</li>
            <li>Select the server name: AAAFx-FX-Demo (For Demo Account) / AAAFx-FX-Real (For Real Account)</li>
            <li>Click on login</li>
            <p>You'll be logged into your trading account</p>
            </ul>
            </div>`,
          },
          {
            label: "How do I log in to a real account on MT5?",
            content: `<div class="content"><p>To login to a real account on the MT5 platform, please follow these steps</p>
            <p>First, you need to create a real account on the portal. To create a live account please follow these steps:</p>
            <ul>
            <li>Register: Click on the “Join now” option on the portal</li>
            <li>Enter your details: Name, email ID, phone number</li>
            <li>Enter the verification code you would have received in your mail</li>
            <li>Choose account base currency and trading platform</li>
            <li>Further, you need to upload documents</li>
            <li>Now deposit funds.</li>
            </ul>
            <p>After this, follow these steps to log in to the platform:</p>
            <ul>
            <li>Click on the “File” option in the menu bar</li>
            <li>Choose “Login to a trade account”</li>
            <li>Enter your trading ID and password</li>
            <li>Select Server: AAA Fx-FX-Real</li>
            <li>Click on 'Login'</li>
            </ul>
            </div>`,
          },
          {
            label: "How do I create/log in to a demo account?",
            content: `<div class="content"><p>To create a demo account, please follow these steps:</p>
            <ol>
            <li>Log in to your web account.</li>
            <li>Go to "Manage Accounts"</li>
            <li>Click on Create a Demo account"</li>
            <li>Choose the trading platform, account leverage, account's base currency and set password.</li>
            </ol>
            <p>Now a demo account has been created for you.</p>
            </div>`,
          },
          {
            label: "Where can I view my commissions?",
            content: `<div class="content"><p>To see commission, please follow these steps:</p>
            <ul>
            <li>Click on the “View” option in the menu bar</li>
            <li>Choose “Terminal”</li>
            <li>Select “Trade Section”</li>
            <li>Right-click on the trade placed</li>
            <li>Enable commissions</li>
            </ul>
            <p>You can now see commissions as a separate column</p>
            </div>`,
          },
          {
            label:
              "How can I change my trading account password from the platform?",
            content: `<div class="content"><p>Follow these steps to change the account password from MT5:</p>
            <ul>
            <li>Click on “Tools” in the menu bar</li>
            <li>Select “Options” > Dialogue Box will appear</li>
            <li>Enter new password > Okay</li>
            </ul>
            <p>OR</p>
            <ul>
            <li>In the navigation window, under accounts > Right-click on the account that you want to change the password for</li>
            <li>Enter the current password and new password > Click on Okay</li>
            </ul>
            </div>`,
          },
          {
            label: "How can I delete/ remove my account from the MT5 platform?",
            content: `<div class="content"><p>To remove or delete your account from the MT5 platform, Right-click on the account that you need to remove and just select the “Delete” option.</p></div>`,
          },
          {
            label: "From where can I download the MT5 platform?",
            content: `<div class="content"><p>To download the platform please follow these steps:</p>
            <ul>
            <li>Click on the “Platform” tab on the portal</li>
            <li>Click on “Download here”</li>
            </ul>
            </div>`,
          },
          {
            label: "Am I allowed to use an EA/ BOT/ Trading Robot?",
            content: `<div class="content"><p>You can definitely use an Expert Advisor in MT5. Check for the ones that are freely available in the navigation window. If you want you can also purchase EA's from the MQL 5 website.</p></div>`,
          },
          {
            label: "How can I attach EA in MT5?",
            content: `<div class="content"><p>To attach an EA, please follow these steps:</p>
            <ul>
            <li>Open Navigator Box (Ctrl N)</li>
            <li>Expert Advisor</li>
            <li>Right-click on EA that you want to use</li>
            <li>Attach to chart </li>
            </ul>
            <p>And in case you are willing to apply for a purchased EA from the MQL site, please follow these steps:</p>
            <ul>
            <li>Go to the “File” tab in the menu bar</li>
            <li>Click on Open Data Folder </li>
            <li>Open the “Experts” folder in the “MQL” file.</li>
            <li>Open the “Experts” folder in the “MQL” file.</li>
            <li>Reopen the MT4 platform again.</li>
            </ul>
            </div>`,
          },
          {
            label: "How do I set up default charts with indicators on MT5?",
            content: `<div class="content"><p>Please follow the steps below:</p>
            <p><b>To Save A Profile:</b></p>
            <p>Once you have everything positioned the way that you want, please go to File - Profiles and click “Save As” Enter a profile name and then press “Ok”</p>
            <p>To load a saved profile, please go to File - Profiles and then select the profile from the list of profiles that comes up. The profile should load.</p>
            <p><b>To Save A Template:</b></p>
            <p>Once you have everything set up on your chart the way you want, please go to Charts - Template - Save Template to save the Template. Enter a name for the template and press save.
            </p>
            </div>`,
          },
          {
            label: "Can I use multiple trading accounts in MT5?",
            content: `<div class="content"><p>Yes, you can use multiple trading accounts in MT5, to add an account follow these steps:</p>
            <ul>
            <li>Right-click on accounts in the navigator box</li>
            <li>Choose “Login to trading account”</li>
            <li>Fill in your trading ID and password</li>
            </ul>
            <p>Your trading account will be inserted in MT5.</p>
            </div>`,
          },
          {
            label: "Where can I check my trading account connection status?",
            content: `<div class="content"><p>Connection status can be checked from the bottom right corner below the terminal window.</p><p>To make the connection status visible, click on “view” > enable status bar</p></div>`,
          },
        ],
        "Trading Platforms - ActTrader": [
          {
            label:
              "How do I log in to my trading account on the ACT on the desktop?",
            content: `<div class="content"><p>Kindly follow the steps below to login into your trading account:</p>
            <ul>
            <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a> to download the trading platform.</li>
            <li>A setup will be downloaded on the system. Click on the setup and the platform will get installed.</li>
            <li>After the platform is installed, enter the username and password for the trading account.</li>
            <li>Select the server- 'Demo' for a demo account and 'Real' for a live account.</li>
            <li>Click on 'Login'. You are logged in to your account.</li>
            </ul>
            </div>`,
          },
          {
            label:
              "How do I log in to my trading account on the ACT on web trader?",
            content: `<div class="content"><p>Kindly follow the steps below to login to your trading account on the web trader:</p>
            <ul>
            <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a> to open web trader</li>
            <li>A new tab will appear.</li>
            <li>Enter the username and password for the trading account.</li>
            <li>Click on 'Login'. You are logged in to your account.</li>
            </ul>
            </div>`,
          },
          {
            label:
              "I am confused on how to install the ACT application on my mobile device. Please help.",
            content: `<div class="content"><p>Kindly follow the steps below to install the application on your mobile device:</p>
            <p><b>ANDROID</b></p>
            <ul>
            <li>Login to your client portal on your mobile device.</li>
            <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a></li>
            <li>Scroll to the bottom of the window and click on 'ACT ANDROID'.</li>
            <li>Google store opens up an application named 'ActDroid' pops up.</li>
            <li>Click on 'Install' and the application will get installed on your device.</li>
            </ul>
            <p><b>IOS</b></p>
            <ul>
            <li>Login to your client portal on your mobile device.</li>
            <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a></li>
            <li>Scroll to the bottom of the window and click on ACT IOS.</li>
            <li>Google store opens up an application named 'ActPhone' pops up.</li>
            <li>Click on 'Install' and the application will get installed on your device.</li>
            </ul>
            </div>`,
          },
          {
            label: "How do I log in to my trading account on my mobile device?",
            content: `<div class="content"><p>Kindly follow the steps below to login into your trading account on your mobile device:</p>
            <p><b>ANDROID</b></p>
            <ul>
              <li>Click on the ActDroid application on your device.</li>
              <li>Press 'Agree' on the license agreement.</li>
              <li>Enter the username and password for the trading account.</li>
              <li>In account type, select the server according to the account type- 'AAAFx-FX-Demo' for a demo account and 'AAAFx-FX-Real' for a live account.</li>
              <li>Click on 'Login'. You are now logged in to your account.</li>
            </ul>
            <p><b>IOS</b></p>
            <ul>
              <li>Click on the ActPhone application on your device.</li>
              <li>Enter the username and password for the trading account.</li>
              <li>In account type, select the server according to the account type- 'AAAFx-FX-Demo' for a demo account and 'AAAFx-FX-Real' for a live account.</li>
              <li>Click on 'Login'. You are now logged in to your account.</li>
            </ul>
            </div>`,
          },
          {
            label: "How do I place an order?",
            content: `<div class="content"><p>You can easily make a trade by clicking the Sell or Buy buttons under the Home tab of the FXVoyager Toolbar.</p><p>You can also place a Market order directly from the Dealing Rates window, Instruments window, or the Dealing Rates Table. Simply left-click on the bid or ask or right-click on the rate and choose Market Order.</p></div>`,
          },
          {
            label:
              "How can I check my trading account balance on the trading platform?",
            content: `<div class="content"><p>You can check the account balance in the following way:</p>
            <ul>
            <li>Login to your trading account.</li>
            </li>Click on the 'Summary' tab.
            </ul>
            <p>All the account information including balance will appear.</p>
            <p>OR</p>
            <p>The account balance can be checked on the bottom left corner of the platform.</p>
            </div>`,
          },
          // {
          //   label:
          //     "How can I check if I have any bonus available on the trading account?",
          //   content: `<div class="content"><p>You can check the account balance in the following way:</p>
          //   <ul>
          //   <li>Login to your trading account.</li>
          //   </li>Click on the 'Summary' tab.
          //   </ul>
          //   <p>All the account information including balance will appear.</p>
          //   <p>The bonus on the account can also be checked in the bottom left corner of the platform.</p>
          //   </div>`,
          // },
          {
            label:
              "On the ACT, can I log in to multiple accounts at the same time?",
            content: `<div class="content"><p>No, ACT does not allow the user to log in to multiple trading accounts at the same time.</p></div>`,
          },
          {
            label: "How can I enable one-click trading?",
            content: `<div class="content">
            <ul>
            <li>Click on the setting's icon on the top left corner</li>
            <li>Select "main setting"</li>
            <li>Checkmark the "One-click trading"</li>
            <li>Click on apply</li>
            </ul>
            </div>`,
          },
          {
            label: "Can I apply indicators on my chart? How?",
            content: `<div class="content"><p>Kindly follow the steps below to apply indicators on your chart:</p>
            <ul>
            <li>Open the chart on which you want to apply the indicator.</li>
            <li>Move your cursor onto the chart. Right-click on the chart area.</li>
            <li>Select 'Set up Indicators'.</li>
            <li>A pop with various indicators will open up.</li>
            <li>Select the indicator and press the forward button " > "</li>
            <li>You can change the settings of the selected indicator on the envelope area before applying.</li>
            <li>Click on "Apply" and then "Ok".</li>
            </ul>
            <p>You will see that the indicator has been applied on your chart.</p>
            </div>`,
          },
          {
            label: "How can I remove an indicator from my chart?",
            content: `<div class="content"><p>Kindly follow the steps below to remove indicators on your chart:</p>
            <ul>
            <li>Open the chart on which you want to remove the indicator.</li>
            <li>Move your cursor onto the chart. Right-click on the chart area.</li>
            <li>Select 'Set up Indicators'.</li>
            <li>A pop with various indicators will open up.</li>
            <li>You can see the list of all applied indicators under "active indicators ".</li>
            <li>Select the indicator and press the reverse button " < "</li>
            <li>Click on "Apply" and then "Ok".</li>
            </ul>
            <p>You will see that the indicator has been removed from the chart.</p>
            </div>`,
          },
          {
            label: "Can I remove multiple indicators from the chart?",
            content: `<div class="content"><p>Yes, you can surely do that.</p>
            <ul>
            <li>Open the chart on which you want to remove the indicator.</li>
            <li>Move your cursor onto the chart. Right-click on the chart area.</li>
            <li>Select 'Set up Indicators'.</li>
            <li>A pop with various indicators will open up.</li>
            <li>You can see the list of all applied indicators under "active indicators ".</li>
            <li>Click the fast reverse button " << " to remove all indicators</li>
            <li>Click on "Apply" and then "Ok".</li>
            </ul>
            </div>`,
          },
          {
            label: "From where can I open the dealing rate window?",
            content: `<div class="content"><p>Kindly follow the steps below to open the dealing rate window:</p>
            <ul>
            <li>Login to your trading account on the platform.</li>
            <li>Click on the "Instrument" tab.</li>
            <li>Move your cursor towards the particular instrument.</li>
            <li>Right-click and select the " Individual dealing rate window".</li>
            <li>A pop-up will open up with the dealing rate of that pair.</li>
            </ul>
            </div>`,
          },
          {
            label: "How can I place an order from the dealing rate window?",
            content: `<div class="content"><p>Kindly follow the steps below to open the dealing rate window:</p>
            <ul>
            <li>Login to your trading account on the platform.</li>
            <li>Click on the "Instrument" tab.</li>
            <li>Move your cursor towards the particular instrument.</li>
            <li>Right-click and select the " Individual dealing rate window".</li>
            <li>A pop-up will open up with the dealing rate of that pair.</li>
            <li>Double click on the pop-up, change the required settings and click on 'OK' to place the order.</li>
            </ul>
            </div>`,
          },
          {
            label:
              "Can I download my trading account statement from the trading platform?",
            content: `<div class="content"><p>Yes, the trading account statement can be downloaded from the platform by following the steps below:</p>
            <ul>
            <li>Login to your trading account on the platform.</li>
            <li>Click on 'Reports'.</li>
            <li>Select 'Account Statement'.</li>
            <li>Select the time format and click on 'Run Report'.</li>
            <li>The report will open up on the screen. Select 'Export to excel'. The report will open on the excel sheet and it can be saved from there.</li>
            </ul>
            </div>`,
          },
          {
            label:
              " I want to modify my Sell order. How can I apply Stop Loss and Take Profit levels to my order?",
            content: `<div class="content"><p>To modify an order, open the 'Trade' tab on the toolbar and click the 'Stop or Limit button'. You can set a Stop or Limit order from the Open Positions window. You can also set a Predefined Stop or Limit in the New Position dialog window.</p></div>`,
          },
          {
            label: "How can I close a trade on the chart?",
            content: `<div class="content"><p>To close or hedge a position, right-click on the position triangle and select the appropriate command.</p></div>`,
          },
          {
            label: "How can I add Trailing Stop on an order?",
            content: `<div class="content"><p>Kindly follow the steps below to add Trailing Stop to your order:</p>
            <ul>
            <li>In the Open Positions window, right-click on the desired position.</li>
            <li>A pop-up menu will appear on the screen.</li>
            <li>Choose the Trailing Stop level and click on 'OK'.</li>
            </ul>
            </div>`,
          },
          {
            label: "Where can I see my trading history?",
            content: `<div class="content"><p>The position history can be viewed in the Closed Positions window.</p></div>`,
          },
          {
            label: "How can I view the trading history on IOS?",
            content: `<div class="content"><p>To view history on Ios, click on Menu > History. By default, up to 15 positions can be viewed. The number of positions can be increased by opening Menu > Settings.</p></div>`,
          },
          {
            label: "How can I open a chart of a trading instrument?",
            content: `<div class="content"><p>To open a chart window on your trading workspace, choose 'Charts' on the Upper Panel Menu and then choose 'New Chart'.</p>
            <p>OR</p>
            <p>Please right-click on an instrument in the Dealing Rates window and select Open Chart. Select the instrument (if you right-clicked on an instrument to reach the New Chart dialog, then that instrument will be pre-selected) and the time scale from the New Chart menu and press OK.
            </p>
            </div>`,
          },
          {
            label: "How can I zoom in and zoom out the chart?",
            content: `<div class="content"><p>On the top of the chart, the plus '+' and minus '-' icons allow the zoom in, zoom out, and default chart display.</p></div>`,
          },
          {
            label: "Can I change the colours of the chart?",
            content: `<div class="content"><p>Yes, you can change the colours of the chart. Right-click on the chart and select 'Chart Settings'. You will see the 'Colors' drop-down menu. The colours can be changed accordingly.</p></div>`,
          },
          {
            label: "Where do I see the price line on the chart?",
            content: `<div class="content"><p>The price line is the blue coloured line in the chart background. It moves as per the price movement of the instrument.</p></div>`,
          },
        ],
        "Trading Conditions": [
          {
            label:
              "Are there any kind of restrictions on adopting trading styles? I am a scalper.",
            content: `<div class="content"><p>Being a trader-friendly broker, we don't restrict any trading styles. You can do hedging, scalping, and use EAs on your trading platform.</p></div>`,
          },
          {
            label: "Do you follow the FIFO rule for trade execution?",
            content: `<div class="content"><p>No, we don't abide by the FIFO rule. The trader can close the trades as per his own strategy.</p></div>`,
          },
          {
            label: "What type of trade execution do you provide?",
            content: `<div class="content"><p>At AAAFx, the trade execution is instant.</p></div>`,
          },
          {
            label:
              "Is there a limit on the number of trades to be placed every month?",
            content: `<div class="content"><p>No, there are no such limits. You can place trades as per your own choice.</p></div>`,
          },
          {
            label: "Can I trade over the weekend?",
            content: `<div class="content"><p>No, trading is not available on the weekends.</p></div>`,
          },
          {
            label: "For how long can I hold my open positions?",
            content: `<div class="content"><p>There is no compulsion on the duration for which a trade can be kept open. You can keep it open as long as you have sufficient free margin in the account.</p></div>`,
          },
          {
            label: "Can I lose more than what I deposit when trading with you?",
            content: `<div class="content"><p>While trading with AAAFx, you cannot lose more than what you deposit because we provide Negative Balance Protection. AAAFx determines at its sole discretion and in good faith, that the occurrence of such negative balance is not due to any malicious or abusive behavior of the client and has not been intentionally caused by the client.</p></div>`,
          },
          {
            label:
              "What if there is no free margin left in my trading account?",
            content: `<div class="content"><p>If there is no free margin left, you can not place any further orders. Also, you can not raise a withdrawal request because you can always withdraw the amount available as a free margin in your account.</p></div>`,
          },
          {
            label: "What kind of spreads do you provide at AAAFx?",
            content: `<div class="content"><p>We provide razor-thin spreads to all our clients irrespective of different account types. </p></div>`,
          },
          {
            label: "What leverage is offered by AAAFx?",
            content: `<div class="content"><p>The maximum leverage offered for AAAFX Europe is 30X.</p></div>`,
          },
          {
            label: "What time zone are your servers set at?",
            content: `<div class="content"><p>It is set at UTC Time [UTC +1 during daylight saving].</p></div>`,
          },
          {
            label: "Where are your servers located?",
            content: `<div class="content"><p>Our servers are located in London and New York.</p></div>`,
          },
          {
            label: "Do you offer trading advice to your clients?",
            content: `<div class="content"><p>Being a broker, we don't provide any kind of trading advice to our clients. For the ideal trading strategies, you can refer to the Zulutrade website and learn as much as you can. Furthermore, you can also read about the relationship between leverage and margin to invest your funds wisely. Forex trading is all about investing your time and money in a judicious manner. Start investing now!</p></div>`,
          },
        ],
      },
    };
  },
  methods: {
    toggleClass(key, index) {
      if (this.currentItem.key === key && this.currentItem.index === index) {
        this.currentItem = {
          key: null,
          index: null,
        };
      } else {
        this.currentItem = {
          key,
          index,
        };
      }
    },
  },
};
</script>
